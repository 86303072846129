var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"table-container data-table-container"},[_c('b-card',{attrs:{"title":_vm.title}},[_vm._t("header"),_c('b-table',{staticClass:"data-table",style:({ minHeight: _vm.minHeight }),attrs:{"hover":"","items":_vm.data.items,"fields":_vm.data.fields,"busy":_vm.loading,"show-empty":"","stacked":"lg"},on:{"update:busy":function($event){_vm.loading=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"small":"","type":"grow","label":"Spinning"}})],1)]},proxy:true},_vm._l((Object.keys(_vm.$scopedSlots)),function(slotName){return {key:slotName,fn:function(slotScope){return [_vm._t(slotName,null,null,slotScope)]}}}),{key:"cell(time)",fn:function(data){return [_c('div',{staticClass:"dt-row"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(data.value))])])]}},{key:"cell(timestamp)",fn:function(data){return [_c('div',{staticClass:"dt-row"},[_c('time-tag',{attrs:{"timestamp":data.value}})],1)]}},{key:"cell(direct)",fn:function(data){return [_c('div',{staticClass:"dt-row"},[_c('DirectTag',{attrs:{"direct":data.value}})],1)]}},{key:"cell(txhash)",fn:function(data){return [_c('div',{staticClass:"dt-row"},[_c('router-link',{staticClass:"link",attrs:{"to":{
              name: 'txDetail',
              params: { hash: data.value },
            }}},[_vm._v(_vm._s(_vm.shortHash(data.value)))])],1)]}},{key:"cell(bucketid)",fn:function(data){return [_c('div',{staticClass:"dt-row"},[_c('router-link',{staticClass:"link",attrs:{"to":{
              name: 'bucket',
              params: { id: data.value },
            }}},[_vm._v(_vm._s(_vm.shortHash(data.value)))])],1)]}},{key:"cell(fullAddress)",fn:function(data){return [_c('div',{staticClass:"dt-row"},[_c('address-link',{attrs:{"address":data.value}})],1)]}},{key:"cell(address)",fn:function(data){return [_c('div',{staticClass:"dt-row"},[_c('address-link',{attrs:{"address":data.value,"short":true}})],1)]}},{key:"cell(from)",fn:function(data){return [_c('div',{staticClass:"dt-row"},[_c('address-link',{attrs:{"address":data.value,"short":true}})],1)]}},{key:"cell(to)",fn:function(data){return [_c('div',{staticClass:"dt-row"},[_c('address-link',{attrs:{"address":data.value,"short":true}})],1)]}},{key:"cell(blocknum)",fn:function(data){return [_c('div',{staticClass:"dt-row"},[_c('router-link',{staticClass:"link",attrs:{"to":{
              name: 'blockDetail',
              params: { revision: data.value },
            }}},[_vm._v("#"+_vm._s(data.value))])],1)]}},{key:"cell(blockhash)",fn:function(data){return [_c('div',{staticClass:"dt-row"},[_c('router-link',{staticClass:"link",attrs:{"to":{
              name: 'blockDetail',
              params: { revision: data.value },
            }}},[_vm._v(_vm._s(_vm.shortHash(data.value)))])],1)]}},{key:"cell(auctionid)",fn:function(data){return [_c('div',{staticClass:"dt-row"},[_c('router-link',{attrs:{"to":{ name: 'auctionDetail', params: { auctionID: data.value } }}},[_vm._v("Bids")])],1)]}},{key:"cell(powReward)",fn:function(data){return [_c('div',{staticClass:"dt-row"},[_c('router-link',{attrs:{"to":{ name: 'powRewards', params: { epoch: data.value } }}},[_vm._v(" Rewards ")])],1)]}},{key:"cell(posReward)",fn:function(data){return [_c('div',{staticClass:"dt-row"},[_c('router-link',{attrs:{"to":{ name: 'posRewards', params: { epoch: data.value } }}},[_vm._v("Rewards")])],1)]}},{key:"cell()",fn:function(data){return [_c('div',{staticClass:"dt-row"},[(data.value.type == 'amount')?_c('amount-tag',{attrs:{"amount":data.value.amount,"token":data.value.token,"precision":data.value.precision}}):_vm._e(),(!data.value.type)?_c('span',[_vm._v(_vm._s(data.value))]):_vm._e()],1)]}}],null,true)},[_c('template',{slot:"empty"},[_c('div',{staticClass:"text-center pt15 text-grey",staticStyle:{"color":"#5c6f8c"}},[_vm._v("No data available.")])])],2),(_vm.pagination.show && _vm.paginateTotal > _vm.pagination.perPage)?_c('div',{staticClass:"data-pagination"},[_c('b-pagination',{attrs:{"align":_vm.pagination.align,"total-rows":_vm.paginateTotal,"per-page":_vm.pagination.perPage},on:{"change":_vm.pgChange},model:{value:(_vm.paginateCurrentPage),callback:function ($$v) {_vm.paginateCurrentPage=$$v},expression:"paginateCurrentPage"}})],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }