import { render, staticRenderFns } from "./Stat.vue?vue&type=template&id=cb470672&scoped=true&lang=pug&"
import script from "./Stat.vue?vue&type=script&lang=js&"
export * from "./Stat.vue?vue&type=script&lang=js&"
import style0 from "./Stat.vue?vue&type=style&index=0&id=cb470672&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb470672",
  null
  
)

export default component.exports