var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',[_c('b-card',{staticClass:"stats"},[(_vm.loading)?_c('div',{staticClass:"position-relative epoch-loading"},[_c('Loading')],1):_c('div',[_c('section',{staticClass:"d-flex justify-content-center title-row"},[_vm._v(" Statistics for Epoch: "+_vm._s(_vm.epoch)+" ")]),_c('section',{staticClass:"row mt-1"},[_c('div',{staticClass:"col-md-4 d-flex align-center"},[_c('span',{staticClass:"success m-1 px-2"},[_vm._v("B")]),_vm._v(" success round producing mBlock"),_c('br')]),_c('div',{staticClass:"col-md-4 d-flex align-center"},[_c('span',{staticClass:"success m-1 px-2"},[_vm._v("KB")]),_vm._v(" success round producing KBlock"),_c('br')]),_c('div',{staticClass:"col-md-4 d-flex align-center"},[_c('span',{staticClass:"fail m-1 px-2"},[_vm._v("F")]),_vm._v(" failed round, no block produced ")]),_c('div',{staticClass:"col-md-4 d-flex align-center"},[_c('span',{staticClass:"success delay-5s m-1 px-2"},[_vm._v("B")]),_vm._v(" success round, delays 5-20s"),_c('br')]),_c('div',{staticClass:"col-md-4 d-flex align-center"},[_c('span',{staticClass:"success delay-20s m-1 px-2"},[_vm._v("B")]),_vm._v(" success round, delays 20-60s"),_c('br')]),_c('div',{staticClass:"col-md-4 d-flex align-center"},[_c('span',{staticClass:"success delay-1m m-1 px-2"},[_vm._v("B")]),_vm._v(" success round, delays > 1min"),_c('br')])]),_c('hr'),_c('section',{staticClass:"d-flex justify-content-between flex-wrap mb-1 hints"},_vm._l((_vm.detail),function(value,key,indx){return _c('span',{key:indx,staticClass:"p-1 rounded"},[_vm._v(" "+_vm._s(key)+": "+_vm._s(value)+" ")])}),0),_c('section',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.stats),function(summary,round){return _c('span',{key:round},[_c('span',{staticClass:"d-block m-1 px-2",class:(summary.status == 1 ? 'success' : 'fail') +
              ' ' +
              (summary.intvl > 60
                ? 'delay-1m'
                : summary.intvl > 20
                ? 'delay-20s'
                : summary.intvl > 5
                ? 'delay-5s'
                : 'no-delay'),style:({ cursor: 'pointer' }),attrs:{"id":'round-' + round,"title":_vm.memberAt(round).name}},[_vm._v(" "+_vm._s(summary.status == 1 ? (summary.k ? 'KB' : 'B') : 'F')+" ")]),_c('b-tooltip',{attrs:{"target":'round-' + round}},[_c('div',[_vm._v("Round: "+_vm._s(round))]),(summary.status == 1)?_c('div',[_vm._v(_vm._s(summary.k ? 'K' : 'm')+"Block: "+_vm._s(summary.b))]):_c('div',[_c('div',[_vm._v("Status: timeout")]),_c('div',[_vm._v("Expected Block: "+_vm._s(summary.b))])]),(summary.status == 1)?_c('div',[_vm._v("Used: "+_vm._s(summary.intvl)+" sec")]):_vm._e(),(summary.status == 1)?_c('div',[_vm._v("Proposer: "+_vm._s(_vm.memberAt(round).name))]):_c('div',[_vm._v("Expected: "+_vm._s(_vm.memberAt(round).name))])])],1)}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }