var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"table-container data-table-container"},[_c('b-card',[_c('div',{staticClass:"header"},[_c('b-row',[_c('b-col',{attrs:{"sm":"0","lg":"4"}}),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('div',{staticClass:"btn-nav"},[_c('b-button-group',_vm._l((_vm.tabs),function(tab,idx){return _c('b-button',{key:idx,attrs:{"variant":tab == _vm.current_tab ? 'primary' : 'outline-primary'},on:{"click":function($event){return _vm.switchTab(tab)}}},[_vm._v(_vm._s(tab))])}),1)],1)]),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('div',{staticClass:"form-group has-search"},[_c('span',{staticClass:"form-control-feedback"},[_c('b-icon',{attrs:{"icon":"search"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.validate_right_search),expression:"validate_right_search"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.validate_right_search)},on:{"input":function($event){if($event.target.composing)return;_vm.validate_right_search=$event.target.value}}})])])],1)],1),(_vm.loading)?_c('Loading'):_c('b-table',{staticClass:"data-table",attrs:{"hover":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items":_vm.validator_data.items,"fields":_vm.validator_data.fields,"show-empty":"","no-local-sorting":"","stacked":"lg"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"sort-changed":_vm.sortingChanged},scopedSlots:_vm._u([{key:"cell(addressWithName)",fn:function(data){return [_c('div',{staticClass:"dt-row"},[_c('address-link',{attrs:{"address":data.value.address,"name":data.value.name}})],1)]}},{key:"cell(totalPoints)",fn:function(data){return [_c('div',{staticClass:"dt-row"},[(data.value.penalty > 0)?_c('router-link',{attrs:{"to":{
              name: 'statDetail',
              params: { address: data.value.address },
            }}},[_vm._v(_vm._s(data.value.penalty))]):_c('span',[_vm._v("-")])],1)]}},{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"dt-row",staticStyle:{"word-break":"break-all"}},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(address)",fn:function(data){return [_c('div',{staticClass:"dt-row",staticStyle:{"word-break":"break-all"}},[_c('router-link',{staticClass:"link",attrs:{"to":{
              name: 'validatorDetail',
              params: { address: data.value },
            }}},[_vm._v(" "+_vm._s(data.value)+" ")])],1)]}},{key:"cell(jailedTime)",fn:function(data){return [_c('time-tag',{attrs:{"timestamp":data.value}})]}},{key:"cell()",fn:function(data){return [_c('div',{staticClass:"dt-row"},[(data.value.type == 'amount')?_c('amount-tag',{attrs:{"amount":data.value.amount,"token":data.value.token,"precision":data.value.precision}}):_c('span',[_vm._v(_vm._s(data.value))])],1)]}}])},[_c('template',{slot:"empty"},[_c('div',{staticClass:"text-center pt15 text-grey",staticStyle:{"color":"#5c6f8c"}},[_vm._v("No data available.")])])],2),(_vm.validate_table_total > _vm.page_size)?_c('b-row',[_c('b-col',{attrs:{"sm":"7","md":"6","offset-sm":"3","offset-md":"3"}},[_c('b-pagination',{attrs:{"total-rows":_vm.validate_table_total,"per-page":_vm.page_size,"align":"fill","size":"sm"},on:{"change":_vm.pageChange},model:{value:(_vm.current_page),callback:function ($$v) {_vm.current_page=$$v},expression:"current_page"}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }