var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"container"},[_c('b-card',{attrs:{"body-class":"block-card-body p-0","header":"Recent Blocks"}},[_c('b-card-text',{staticClass:"blk-card"},[(_vm.loading)?_c('Loading'):_vm._e(),(!_vm.loading)?_c('ul',{staticClass:"block-list"},_vm._l((_vm.recent_blocks),function(block){return _c('li',{key:block.id,staticClass:"block-detail"},[_c('div',{staticClass:"height-view"},[_c('div',{staticClass:"block-icon"}),_c('div',{staticClass:"height"},[_c('h4',{staticClass:"number"},[_c('router-link',{attrs:{"to":{
                    name: 'blockDetail',
                    params: { revision: block.number },
                  }}},[_vm._v(" "+_vm._s(block.number))])],1),_c('span',{staticClass:"ago"},[_c('time-tag',{attrs:{"timestamp":block.timestamp}})],1)])]),_c('div',{staticClass:"signed-view"},[_c('p',[_vm._v("Proposer")]),(block.beneficiaryName)?_c('router-link',{staticClass:"link",attrs:{"to":{
                name: 'address',
                params: { address: block.beneficiary },
              }}},[_vm._v(_vm._s(_vm.shortAddr(block.beneficiaryName, 12)))]):_c('router-link',{staticClass:"link",attrs:{"to":{
                name: 'address',
                params: { address: block.beneficiary },
              }}},[_vm._v(_vm._s(_vm.shortAddr(block.beneficiary, 12)))])],1),_c('div',{staticClass:"detail-view"},[_c('span',{staticClass:"detail"},[_vm._v(_vm._s(block.txCount)+" tx")])])])}),0):_vm._e()],1),_c('b-card-footer',{staticClass:"p-0"},[_c('b-btn',{staticClass:"p-2",attrs:{"variant":"outline-primary","block":"","size":"sm","to":{ name: 'blockList' }}},[_vm._v("View all Blocks "),_c('b-icon',{attrs:{"icon":"arrow-right"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }